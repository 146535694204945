import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import UnAuthorized from '../unauthorized';
import { useCookies } from "react-cookie";

export const ProtectedRoute = () => {
  
  const { oktaAuth, authState } = useOktaAuth();
  const [ssoCookies] = useCookies(['aid', 'rid']);
  return (<Outlet />);
}
//undo: below lines
//   if((!authState) || (authState && authState.isAuthenticated) || 
//       (ssoCookies?.aid && ssoCookies?.rid )){
//         console.log('security routes')
//         return (<Outlet />)
//       }
//   else
//     return (<UnAuthorized />);

// }
