const OKTA_DOMAIN = process.env.NODE_ENV === 'production' ? `#{OKTA_DOMAIN}` : `tal-ciam-dev.okta.com`;
const CLIENT_ID = process.env.NODE_ENV === 'production' ? '#{CLIENT_ID}' : '0oa3kkdk1wOeQsQbO3l7';
const CUSTOM_AUTH_SERVER_ID = process.env.NODE_ENV === 'production' ? '#{CUSTOM_AUTH_SERVER_ID}' : 'aus3kkhhvmlFiqLem3l7';
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/${CUSTOM_AUTH_SERVER_ID}`;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}/`;
const OKTA_EMAIL_SUFFIX = 'claimassisttal.com.au';

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: REDIRECT_URI,
    scopes: ['offline_access', 'openid', 'profile', 'email'],
    pkce: true,
    tokenManager: {
      storage: 'sessionStorage'
    }
  },
 oktaEmail:{
  suffix: OKTA_EMAIL_SUFFIX
 },
 oktaDomain:{
  domain: OKTA_DOMAIN
 }
};


