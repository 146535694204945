export const VALIDATE_MFA_QUERY = `mutation ValidateMfa($passCodeValidationRequest: PassCodeValidationRequestInput!) {
    validateMfa(passCodeValidationRequest: $passCodeValidationRequest) {
        isSuccess
   }
  }`;

export const SUBMIT_TFN_FORM_QUERY = `mutation SubmitTfnForm($tfnSubmitModel: TfnSubmitModelInput!) {
  submitTfnForm(tfnSubmitModel: $tfnSubmitModel) {
  response
}
}`;

export const RESEND_MFA = `mutation ResendMfa($mfaConfigRequest : MfaConfigRequestInput!)
    {
        resendMfa(mfaConfigRequest: $mfaConfigRequest)
        {
            isSuccess
        }
     }`;

export const ADDRESS_SEARCH = `query memberAddress($searchParam: String!) {
      memberAddress(searchParam: $searchParam) {
        searchAddressModel{
          addressLine
          }
     }# getMemberAddress
    }`;
