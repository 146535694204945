import Header from "../common/header";
import Footer from "../common/footer";
import { GelHeading3, GelDisplay4 } from "@tal-gel/components";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelLink,
  GelParagraph,
  GelHeading4,
  GelLabel,
  GelIcon,
} from "@tal-gel/components";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { GET_SUCCESSPAGE_CONTENT_QUERY } from "../graphql/graphql-contentful";
import { API } from "../constants/constants";
import { getGelTokens } from "@tal-gel/theming";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SuccessPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [contentfulData, setcontenfulData] = useState<null | {
    mmcTfnPageCollection: any;
  }>(null);

  const origin = sessionStorage.getItem("origin") ?? "";

  useEffect(() => {
    if (!state) {
      sessionStorage.removeItem("origin");
      console.log(atob(origin));

      if (atob(origin).indexOf("?source=") > 0) {
        window.location.replace(atob(origin));
      } else {
        sessionStorage.removeItem("origin");
        window.location.replace(atob(origin) + "?source=TFN");
      }
    }

    //contentful call
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_SUCCESSPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });

    setTimeout(() => {
      sessionStorage.removeItem("origin");

      if (atob(origin).indexOf("?source=") > 0) {
        window.location.replace(atob(origin));
      } else {
        sessionStorage.removeItem("origin");
        window.location.replace(atob(origin) + "?source=TFN");
      }
    }, 5000);
  }, []);
  return (
    <div
      style={{
        backgroundColor: getGelTokens().global.themeColorGrayT05,
        paddingBottom: "100px",
      }}
    >
      <Header
        title={contentfulData?.mmcTfnPageCollection.items[0]?.successPageTitle}
      />
      <GelContainerLite>
        <GelBoxLayout
          gap="medium"
          alignment="center"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          padding="large"
          margin="large"
          style={{
            marginTop: `-${getGelTokens().global.sizeBaseX15}px`,
            backgroundColor: getGelTokens().global.themeColorWhite,
            border: `${getGelTokens().global.borderSizeSm}px solid`,
            borderColor: getGelTokens().global.themeColorGrayT20,
          }}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{
              fontSize: `${getGelTokens().global.sizeBaseUnit}em`,
              color: getGelTokens().global.themeColorBackgroundSuccess,
            }}
          />
          <GelLabel large>
            {contentfulData?.mmcTfnPageCollection.items[0]?.successPageText1}
          </GelLabel>
          <GelLabel large>
            {contentfulData?.mmcTfnPageCollection.items[0]?.successPageText2}
          </GelLabel>
        </GelBoxLayout>
      </GelContainerLite>
      <Footer />
    </div>
  );
};

export default SuccessPage;
