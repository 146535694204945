import "./App.css";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import HttpClientService from "./common/api/http-client.provider";
import { useCallback } from "react";
import config from "./config";
import { Outlet, useNavigate } from "react-router-dom";
import {
  GelPageLayout,
  GelPageContent,
  GelPageFooter,
  GelPageHeader,
  GelPageSideNav,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import Header from "./common/header";
import Footer from "./common/footer";
import AppRoutes from "./pages/app.routing";

function App() {
  const oktaAuth = new OktaAuth(config.oidc);
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    (oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
        replace: true,
      });
    },
    []
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <HttpClientService>
        <div className="App">
          <AppRoutes />
          <Outlet />
        </div>
      </HttpClientService>
    </Security>
  );
}

export default App;
