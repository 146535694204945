class Storage {

    setItem(itemKey: string, itemVal: string) {
      sessionStorage.setItem(itemKey, itemVal);
    }
  
    getItem(itemKey: string) {
      return sessionStorage.getItem(itemKey);
    }
  
    removeItem(itemKey: string) {
      sessionStorage.removeItem(itemKey);
    }
  }
  
  export default new Storage();
  