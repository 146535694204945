export const TfnNumberTooltip = (
  <div>
    <div>
      <p>
        <b>What is your tax file number (TFN)?</b>
      </p>
      <p>
        As your payer, we are authorised by the Taxation Administration Act 1953
        to request your TFN. It’s not an offence not to quote your TFN. However,
        quoting your TFN reduces the risk of administrative errors and having
        extra tax withheld. As your payer, we are required to withhold the top
        rate of tax from all payments made to you if you do not provide your TFN
        or claim an exemption from quoting your TFN.
      </p>
      <b>How do you find your TFN?</b>
    </div>

    <ul>
      <li>your income tax notice of assessment</li>
      <li>correspondence that the ATO sends you</li>
      <li>a payment summary that a payer issues to you.</li>
    </ul>

    <p>
      If you have a tax agent, they may also be able to tell you. If you still
      can’t find your TFN, you can phone the ATO on 13 28 61 between 8.00am and
      6.00pm, Monday to Friday. If you phone the ATO, the ATO needs to know they
      are talking to the correct person before discussing your tax affairs. The
      ATO will ask you for details only you, or your authorised representative,
      would know.
    </p>

    <b>You don’t have a TFN</b>
    <br />
    <p>
      If you don’t have a TFN and want to provide a TFN to us, you will need to
      apply for one. For more information about applying for a TFN, visit{" "}
      <a target="_blank" href="https://www.ato.gov.au/tfn">
        ato.gov.au/tfn
      </a>
    </p>

    <span>
      <p>You may be able to claim an exemption from quoting your TFN.</p>
      Select the appropriate option if you:
    </span>
    <ul>
      <li>
        have lodged a TFN application form or made an enquiry to obtain your
        TFN. You now have 28 days to provide your TFN to us, and we must
        withhold at the standard rate during this time. After 28 days, if you
        haven’t given your TFN to your payer, they will withhold the top rate of
        tax from future payments
      </li>
      <li>
        are claiming an exemption from quoting a TFN because you are under 18
        years of age and do not earn enough to pay tax, or
      </li>
      <li>
        are an applicant or recipient of certain pensions, benefits or
        allowances from the:
        <ul>
          <li>
            Department of Human Services – however, you will need to quote your
            TFN if you receive a Newstart, Youth or sickness allowance, or an
            Austudy or parenting payment
          </li>
          <li>
            Department of Veterans’ Affairs – a service pension under the
            Veterans’ Entitlement Act 1986
          </li>
          <li>Military Rehabilitation and Compensation Commission. </li>
        </ul>
      </li>
    </ul>
    <span>
      <b>Providing your TFN to your super fund</b>
      <br />
      As your payer, we must give your TFN to the super fund that we pay your
      contributions to. If your super fund doesn’t have your TFN, you can
      provide it to them separately. This ensures:
    </span>
    <ul>
      <li>
        your super fund can accept all types of contributions to your accounts
      </li>
      <li>
        additional tax will not be imposed on contributions as a result of
        failing to provide your TFN
      </li>
      <li>you can trace different super accounts in your name.</li>
    </ul>
  </div>
);

export const PaidTooltip = (
  <div>
    <p>
      <b>On what basis are you paid?</b>
    </p>
    <p>
      Select the appropriate option according to the basis of your payment. If
      you are not sure, you can check your basis of payment with us, as your
      payer.
    </p>
  </div>
);

export const LoanTooltip = (
  <div>
    <p>
      <b>
        Do you have a Higher Education Loan Program (HELP), VET Student Loan
        (VSL), Financial Supplement (FS), Student Start up Loan (SSL) or Trade
        Support Loan (TSL) debt?
      </b>
    </p>
    <p>
      Answer <b>yes</b> if you have a HELP, VSL, FS, SSL or TSL debt.{" "}
    </p>
    <p>
      Answer <b>no</b> if you do not have a HELP, VSL, FS, SSL or TSL debt, or
      you have repaid your debt in full.{" "}
    </p>
    <span>You have a HELP debt if either:</span>
    <ul>
      <li>
        the Australian Government lent you money under HECS-HELP, FEE-HELP,
        OS-HELP, VET FEE-HELP, VET Student loans prior to 1 July 2019 or
        SA-HELP.
      </li>
      <li>
        you have a debt from the previous Higher Education Contribution Scheme
        (HECS).
      </li>
    </ul>
    <p>You have a SSL debt if you have an ABSTUDY SSL debt. </p>
    <p>
      You have a separate VSL debt that is not part of your HELP debt if you
      incurred it from 1 July 2019.
    </p>
    <p>
      <b>Have you repaid your HELP, VSL, FS, SSL or TSL debt? </b>
      <br />
      When you have repaid your HELP, VSL, FS, SSL or TSL debt, you need to
      complete a Withholding declaration (NAT 3093) to notify us of the change
      in your circumstances.
    </p>
  </div>
);

export const ResidentTooltip = (
  <div>
    <p>
      <b>
        Are you an Australian resident for tax purposes or a working holiday
        maker?
      </b>
    </p>
    <span>
      Generally, the ATO considers you to be an Australian resident for tax
      purposes if you:
    </span>
    <ul>
      <li>
        have always lived in Australia or you have come to Australia and now
        live here permanently
      </li>
      <li>
        are an overseas student doing a course that takes more than six months
        to complete
      </li>
      <li>migrate to Australia and intend to reside here permanently. </li>
    </ul>
    <p>
      If you go overseas temporarily and don’t set up a permanent home in
      another country, you may continue to be treated as an Australian resident
      for tax purposes.
    </p>
    <p>
      If you are in Australia on a working holiday visa (subclass 417) or a work
      and holiday visa (subclass 462) you must select the working holiday maker
      option. Special rates of tax apply for working holiday makers. For more
      information about working holiday makers, visit{" "}
      <a target="_blank" href="https://www.ato.gov.au/whm">
        ato.gov.au/whm
      </a>
    </p>
    <p>
      If you’re not an Australian resident for tax purposes or a working holiday
      maker, select the foreign resident option, unless you are in receipt of an
      Australian Government pension or allowance. Temporary residents can claim
      super when leaving Australia, if all requirements are met. For more
      information, visit{" "}
      <a target="_blank" href="https://www.ato.gov.au/departaustralia">
        ato.gov.au/departaustralia
      </a>
    </p>
    <p>
      <b>Foreign resident tax rates are different</b>
      <br />A higher rate of tax applies to a foreign resident’s taxable income
      and foreign residents are not entitled to a tax-free threshold nor can
      they claim tax offsets to reduce withholding, unless you are in receipt of
      an Australian Government pension or allowance. To check your Australian
      residency status for tax purposes or for more information, visit{" "}
      <a target="_blank" href="https://www.ato.gov.au/residency">
        ato.gov.au/residency
      </a>
    </p>
  </div>
);

export const TaxFreeTooltip = (
  <div>
    <p>
      <b>Do you want to claim the tax free threshold from this payer? </b>
    </p>
    <p>
      The tax-free threshold is the amount of income you can earn each financial
      year that is not taxed. By claiming the threshold, you reduce the amount
      of tax that is withheld from your pay during the year.
    </p>
    <span>
      Answer <b>yes</b> if you want to claim the tax-free threshold, you are an
      Australian resident for tax purposes, and one of the following applies:
    </span>
    <ul>
      <li>
        you are not currently claiming the tax-free threshold from another payer
      </li>
      <li>
        you are currently claiming the tax-free threshold from another payer and
        your total income from all sources will be less than the tax-free
        threshold.
      </li>
    </ul>
    <p>
      Answer <b>yes</b> if you are a foreign resident in receipt of an
      Australian Government pension or allowance.
      <br />
      Answer <b>no</b> if none of the above applies or you are a working holiday
      maker.
    </p>
    <p>
      If you receive any taxable government payments or allowances, such as
      Newstart, Youth Allowance or Austudy payment, you are likely to be already
      claiming the tax free threshold from that payment.
    </p>
    <p>
      For more information about the current tax-free threshold, which payer you
      should claim it from, or how to vary your withholding rate, visit{" "}
      <a target="_blank" href="https://www.ato.gov.au/taxfreethreshold">
        ato.gov.au/taxfreethreshold
      </a>
    </p>
  </div>
);
