import React from "react";
import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

const ServerError = () => {

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
            <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                Sorry we are not able to process your request. Please try again later.</GelLabel>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default ServerError;