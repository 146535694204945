import axios from "axios";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { CookieConfig } from "../../constants/constants";

const HttpClientService = (props: { children?: any }) => {
  const [ssoCookies, setSsoCookies, removeSsoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
  const navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.request.use(
      (request) => {
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.headers['x-aid'] && response.headers['x-rid']) {
          removeSsoCookies('aid', { domain: CookieConfig.domain, path: CookieConfig.path });
          removeSsoCookies('rid', { domain: CookieConfig.domain, path: CookieConfig.path });

          setSsoCookies('aid', response.headers['x-aid'], {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          });
          setSsoCookies('rid', response.headers['x-rid'], {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
          });
        }
        return response;
      },
      async (error) => {
        console.log("error", error.response.status);
        if (error.response.status === 401) {
          navigate("/error");
        }
        return Promise.reject(error);
      }
    );
  });

  return props.children;
};

export default HttpClientService;
