import { GelThemeProvider } from '@tal-gel/theming';
import { GelScreenDetector } from '@tal-gel/components';
import { useContext } from 'react';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { ThemeContext } from './common/authentication/themes.context';
import { HOST_BRAND_MAP } from './constants/constants';
import SESSION_STORAGE_KEY from './constants/storage.constant';

const GelThemes = () => {

  const { themeData } = useContext(ThemeContext)

  function getAppTheme() { 
    
    let sessionTheme = window.sessionStorage.getItem(SESSION_STORAGE_KEY.THEME);

    return sessionTheme ? HOST_BRAND_MAP[sessionTheme] : 
      (themeData?.theme ? HOST_BRAND_MAP[themeData.theme] : "tal")

   } 

    return (
        <GelThemeProvider theme={getAppTheme()}>
          <GelScreenDetector>
            <HashRouter>
              <App />
            </HashRouter>
          </GelScreenDetector>
        </GelThemeProvider>
    );
}

export default GelThemes;