import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelLink
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

const UnAuthorized = () => {

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                    Looks like are not signed in or your session is terminated due to inactivity. Please login again using valid credentials</GelLabel>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default UnAuthorized;