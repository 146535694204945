import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./setUpTalGel";
import GelThemes from "./gelThemes";
import { ThemeContextProvider } from "./common/authentication/themes.context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const IndexPage = () => {
  return (
    <ThemeContextProvider>
      <GelThemes />
    </ThemeContextProvider>
  );
};

root.render(<IndexPage />);

reportWebVitals();
